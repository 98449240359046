// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-alerts-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/alerts.js" /* webpackChunkName: "component---src-pages-components-alerts-js" */),
  "component---src-pages-components-badges-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/badges.js" /* webpackChunkName: "component---src-pages-components-badges-js" */),
  "component---src-pages-components-breadcrumb-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/breadcrumb.js" /* webpackChunkName: "component---src-pages-components-breadcrumb-js" */),
  "component---src-pages-components-button-group-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/button-group.js" /* webpackChunkName: "component---src-pages-components-button-group-js" */),
  "component---src-pages-components-buttons-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/buttons.js" /* webpackChunkName: "component---src-pages-components-buttons-js" */),
  "component---src-pages-components-cards-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/cards.js" /* webpackChunkName: "component---src-pages-components-cards-js" */),
  "component---src-pages-components-carousel-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/carousel.js" /* webpackChunkName: "component---src-pages-components-carousel-js" */),
  "component---src-pages-components-collapse-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/collapse.js" /* webpackChunkName: "component---src-pages-components-collapse-js" */),
  "component---src-pages-components-dropdowns-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/dropdowns.js" /* webpackChunkName: "component---src-pages-components-dropdowns-js" */),
  "component---src-pages-components-forms-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/forms.js" /* webpackChunkName: "component---src-pages-components-forms-js" */),
  "component---src-pages-components-input-group-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/input-group.js" /* webpackChunkName: "component---src-pages-components-input-group-js" */),
  "component---src-pages-components-jumbotron-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/jumbotron.js" /* webpackChunkName: "component---src-pages-components-jumbotron-js" */),
  "component---src-pages-components-list-group-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/list-group.js" /* webpackChunkName: "component---src-pages-components-list-group-js" */),
  "component---src-pages-components-media-object-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/media-object.js" /* webpackChunkName: "component---src-pages-components-media-object-js" */),
  "component---src-pages-components-modal-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/modal.js" /* webpackChunkName: "component---src-pages-components-modal-js" */),
  "component---src-pages-components-navbar-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/navbar.js" /* webpackChunkName: "component---src-pages-components-navbar-js" */),
  "component---src-pages-components-navs-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/navs.js" /* webpackChunkName: "component---src-pages-components-navs-js" */),
  "component---src-pages-components-pagination-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/pagination.js" /* webpackChunkName: "component---src-pages-components-pagination-js" */),
  "component---src-pages-components-popovers-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/popovers.js" /* webpackChunkName: "component---src-pages-components-popovers-js" */),
  "component---src-pages-components-progress-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/progress.js" /* webpackChunkName: "component---src-pages-components-progress-js" */),
  "component---src-pages-components-scrollspy-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/scrollspy.js" /* webpackChunkName: "component---src-pages-components-scrollspy-js" */),
  "component---src-pages-components-spinners-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/spinners.js" /* webpackChunkName: "component---src-pages-components-spinners-js" */),
  "component---src-pages-components-toasts-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/toasts.js" /* webpackChunkName: "component---src-pages-components-toasts-js" */),
  "component---src-pages-components-tooltips-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/components/tooltips.js" /* webpackChunkName: "component---src-pages-components-tooltips-js" */),
  "component---src-pages-index-js": () => import("/Users/unk/works/bootstrap-crom-theme/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/unk/works/bootstrap-crom-theme/.cache/data.json")

